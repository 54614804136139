import React from "react";
//import ReactDOM from "react-dom";
//import { PDFDownloadLink, PDFViewer, Page, Text, View, Document, StyleSheet, Font, Image } from "@react-pdf/renderer";
import { BlobProvider, Page, Text, View, Document, StyleSheet, Font, Image } from "@react-pdf/renderer";
import Header from "./header.component";
import moment from "moment";
import getAge from "../misc/getAge";
import getZeroPad from "../misc/getZeroPad";
import getSalutation from "../misc/getSalutation";
import convertRefValueToStr from "../misc/convertRefValueToStr";

Font.register({
    family: "Segoe UI",
    fonts: [
        {
            src: "/fonts/Segoe UI/Segoe UI.ttf"
        },
        {
            src: "/fonts/Segoe UI/Segoe UI Bold.ttf",
            fontWeight: "bold"
        },
        {
            src: "/fonts/Segoe UI/Segoe UI Italic.ttf",
            fontWeight: "normal",
            fontStyle: "italic"
        }
    ]
});

// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: "col",
        marginLeft: 30,
        marginTop: 10,
        marginRight: 20,
        fontFamily: "Segoe UI",
        fontSize: "9",
        paddingRight: 60
    },
    section: {
        padding: 10
    },
    tableHeader: {
        fontWeight: "bold",
        paddingBottom: 5
    },
    tableContent: {
        paddingBottom: 5
    }
});

// Create Document Component

const MyDocument = ({ patient, labResultData, indicators, authorizedBy, qrImage, notes }) => (
    <Document>
        <Page size="A4" style={styles.page}>
            <Header />
            <View>
                <Text style={{ textAlign: "right" }}>Clinical Pathologist: dr. Elvin Richela Lawanto, Sp.PK</Text>
                <Text style={{ textAlign: "center", fontSize: "14" }}>Hasil Laboratorium / Laboratorium Result</Text>
            </View>
            <View style={styles.section}>
                <View style={{ flexDirection: "col" }}>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "20%" }]}>No Pemeriksaan / Exam No</Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>
                            {moment(labResultData.completedAt).format("YYMMDD")}
                            {getZeroPad(labResultData.lab_result_no, 3)}
                        </Text>
                        <Text style={[styles.tableHeader, { width: "20%" }]}>Sampel diambil / Sample taken</Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>{labResultData.sampleTakenAt && moment(labResultData.sampleTakenAt).format("DD-MM-YYYY HH:mm")}</Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "20%" }]}>MRN</Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>{patient.mrn}</Text>
                        <Text style={[styles.tableHeader, { width: "20%" }]}>Sampel diterima / Sample received</Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>{labResultData.sampleReceivedAt && moment(labResultData.sampleReceivedAt).format("DD-MM-YYYY HH:mm")}</Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "20%" }]}>Nama / Name</Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>
                            {getSalutation(patient.fullName, patient.sex, patient.dateOfBirth, patient.maritalStatus)}
                            {patient.fullName}
                        </Text>
                        <Text style={[styles.tableHeader, { width: "20%" }]}>Tanggal Selesai / Completed Date</Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>{moment(labResultData.completedAt).format("DD-MM-YYYY HH:mm")}</Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "20%" }]}>Jenis Kelamin / Sex</Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>
                            {patient.sex} / {patient.sex === "Laki-laki" ? "Male" : "Female"}
                        </Text>
                        <Text style={[styles.tableHeader, { width: "20%" }]}>Tgl Lahir (Usia) / DOB (Age)</Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>
                            {moment(patient.dateOfBirth).format("DD-MM-YYYY")} / {getAge(patient.dateOfBirth, new Date())}
                        </Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "20%" }]}>Dokter / Doctor</Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>{labResultData.doctor ? labResultData.doctor : labResultData.requestedBy}</Text>
                        <Text style={[styles.tableHeader, { width: "20%" }]}>Alamat / Address</Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>{labResultData.from}</Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "20%" }]}>Jenis Pemeriksaan / Exam</Text>
                        <Text style={[styles.tableContent, { width: "80%" }]}>
                            {labResultData.category} - {labResultData.name}
                        </Text>
                    </View>
                </View>

                <View style={{ flexDirection: "col" }}>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "30%" }]}>Pemeriksaan / Exam</Text>
                        <Text style={[styles.tableHeader, { width: "15%" }]}>Hasil / Result</Text>
                        <Text style={[styles.tableHeader, { width: "10%" }]}></Text>
                        <Text style={[styles.tableHeader, { width: "10%" }]}>Satuan / Unit</Text>
                        <Text style={[styles.tableHeader, { width: "35%" }]}>Nilai Rujukan Normal / Reference</Text>
                    </View>
                    {labResultData.results.length > 0 &&
                        labResultData.results.map((item, index) => (
                            <View key={index} style={{ flexDirection: "row" }}>
                                <Text style={[styles.tableContent, { width: "30%" }]}>
                                    {item.name}
                                    {item.name.indexOf("Antigen SARS") >= 0 && (
                                        <>
                                            <br></br>
                                            <sup>Sampel Swab Nasofaring</sup>
                                        </>
                                    )}
                                </Text>
                                {indicators[index] === "L" || indicators[index] === "H" || indicators[index] === "Positive" || indicators[index] === "Inconclusive" ? (
                                    <Text style={[styles.tableContent, { width: "15%", color: "red", fontWeight: "bold" }]}>{item.value}</Text>
                                ) : (
                                    <Text style={[styles.tableContent, { width: "15%", fontWeight: "bold" }]}>{labResultData.attachmentUrl ? "TERLAMPIR" : item.value}</Text>
                                )}
                                <Text style={[styles.tableContent, { width: "10%" }]}>{indicators[index]}</Text>
                                <Text style={[styles.tableContent, { width: "10%" }]}>{item.unit ? item.unit.replace("<sup>", "^").replace("</sup>", "") : ""}</Text>
                                <Text style={[styles.tableContent, { width: "35%" }]}>{convertRefValueToStr(item.refValues, patient.dateOfBirth, item.name).replace("<br/>", ". ")}</Text>
                            </View>
                        ))}
                </View>
                {notes && notes.length > 0 && (
                    <View style={{ flexDirection: "col", marginTop: 10 }}>
                        <Text style={[styles.tableHeader]}>Catatan / Notes</Text>
                        {notes.map((note, index) => (
                            <View style={{ flexDirection: "row" }} key={index}>
                                <Text style={{ marginHorizontal: 8 }}>•</Text>
                                <Text>{note}</Text>
                            </View>
                        ))}
                    </View>
                )}
                <View style={{ flexDirection: "row", marginTop: 10 }}>
                    <View style={{ flexDirection: "col", width: "30%" }}>
                        <Text style={{ textAlign: "left", paddingBottom: 5 }}>&nbsp;</Text>
                        <Text style={{ textAlign: "left", paddingBottom: 5 }}>Penanggung jawab / Clinical Pathologist</Text>
                        <Image style={{ height: 40, width: 80 }} src="/img/dr. Elvin Richela Lawanto, Sp.PK.png" />
                        <Text style={{ textAlign: "left", paddingBottom: 5 }}>dr. Elvin Richela Lawanto, Sp.PK</Text>
                    </View>
                    <View style={{ flexDirection: "col", width: "40%", alignItems: "center" }}>
                        <Image source={qrImage} style={{ height: 75, width: 75 }} />
                    </View>
                    <View style={{ flexDirection: "col", width: "30%", alignItems: "right" }}>
                        <Text style={{ textAlign: "right", paddingBottom: 5 }}>Cilegon, {moment(new Date()).format("DD-MM-YYYY")}</Text>
                        <Text style={{ textAlign: "right", paddingBottom: 5 }}>Diotorisasi oleh / Authorized by</Text>
                        <Image style={{ height: 40, width: "auto", marginLeft: "auto" }} src={"/img/" + authorizedBy + ".png"} />
                        <Text style={{ textAlign: "right", paddingBottom: 5 }}>{authorizedBy}</Text>
                    </View>
                </View>
            </View>
        </Page>
    </Document>
);

export default function Lab({ patient, labResultData, indicators, authorizedBy, qrImage, notes }) {
    return (
        <BlobProvider document={<MyDocument patient={patient} labResultData={labResultData} indicators={indicators} authorizedBy={authorizedBy} qrImage={qrImage} notes={notes} />}>
            {({ url, loading }) => (
                <a
                    className="btn btn-sm btn-outline-success"
                    href={url}
                    target="_blank"
                    rel="noreferrer"
                    download={moment(labResultData.completedAt).format("YYMMDD") + getZeroPad(labResultData.lab_result_no, 3) + " - " + patient.mrn + " - " + labResultData.name + ".pdf"}
                >
                    {loading ? "Loading ..." : "Download PDF - " + labResultData.name}
                </a>
            )}
        </BlobProvider>
    );
}

/*
        <PDFDownloadLink
            className="btn btn-sm btn-outline-success"
            document={<MyDocument patient={patient} labResultData={labResultData} indicators={indicators} authorizedBy={authorizedBy} qrImage={qrImage} notes={notes} />}
            fileName={moment(labResultData.completedAt).format("YYMMDD") + getZeroPad(labResultData.lab_result_no, 3) + " - " + patient.mrn + " - " + labResultData.name + ".pdf"}
        >
            {({ blob, url, loading, error }) => (loading ? "Loading ..." : "Download PDF - " + labResultData.name)}
        </PDFDownloadLink>
    */

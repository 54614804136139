export default function toEnglishWord(word) {
    if (word === "Laki-laki") return "Male";
    if (word === "Perempuan") return "Female";
    if (word === "Tidak Ada") return "N/A";
    if (word === "Tidak") return "No";
    if (word === "Ya") return "Yes";
    if (word === "Baik") return "Good";
    if (word === "Membesar") return "Enlargement Found";
    if (word === "Tidak Membesar") return "No Enlargement";
    if (word === "Cembung") return "Convex";
    if (word === "Cekung") return "Concave";
    if (word === "Meningkat") return "Increased";
    if (word === "Menurun") return "Decreased";
    if (word === "Reguler") return "Regular";
    if (word === "Ireguler") return "Irregular";
    if (word === "Datar") return "Flat";
    if (word === "Supel") return "Supple";
    if (word === "Simetris") return "Symmetrical";
    if (word === "Tidak Simetris") return "Asymmetrical";
    if (word === "Vesikuler") return "Vesicular";
    if (word === "Bronkhial") return "Bronchial";
    if (word === "Trakeal") return "Tracheal";
    if (word.includes("Kanan")) return word.replace("Kanan", "Right");
    if (word.includes("Kiri")) return word.replace("Kiri", "Left");
    if (word.includes("hari") || word.includes("bulan") || word.includes("tahun")) return word.replace("hari", "days").replace("bulan", "months").replace("tahun", "years");
    if (word.includes("Hasil")) return word.replace("Hasil", "Result");
    return word;
}

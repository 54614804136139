import React from "react";
import moment from "moment";
import "moment/locale/id";
import getRoman from "../misc/getRoman";
import getZeroPad from "../misc/getZeroPad";

export default function Vaccination({ patient, letter }) {
    return (
        <div>
            <h5 className="text-center">Sertifikat Vaksinasi</h5>
            <h6 className="text-center">Vaccination Certificate</h6>
            <table className="table table-sm">
                <tbody>
                    <tr>
                        <th>No</th>
                        <td>
                            {letter.letter_no
                                ? getZeroPad(letter.letter_no, 3) + "/SVK/RSCS/" + getRoman(moment(letter.createdAt).format("M")) + "/" + moment(letter.createdAt).format("YYYY")
                                : letter.letter_no}
                        </td>
                    </tr>
                    <tr>
                        <th>Tanggal / Date</th>
                        <td>{moment(letter.createdAt).format("DD-MM-YYYY")}</td>
                    </tr>
                    <tr>
                        <th>Nama / Name</th>
                        <td>{patient.fullName}</td>
                    </tr>
                    <tr>
                        <th>Tanggal Lahir / DOB</th>
                        <td>{moment(patient.dateOfBirth).format("DD-MM-YYYY")}</td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            <table className="table table-sm">
                                <thead>
                                    <tr>
                                        <th>No</th>
                                        <th>Nama Vaksin / Vaccine Name</th>
                                        <th>Tanggal / Date</th>
                                        <th>Diberikan oleh / Administered by</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {letter.medicines.map((med, index) => (
                                        <tr key={index}>
                                            <td>{parseInt(index + 1)}</td>
                                            <td>
                                                {med.description} ({med.name})
                                            </td>
                                            <td>{moment(new Date(med.createdAt)).format("DD-MM-YYYY")}</td>
                                            <td>{med.requestedBy}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </td>
                    </tr>
                    <tr className="table-primary text-center">
                        <th colSpan={2}>Terverifikasi / Verified</th>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

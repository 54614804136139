import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import "moment/locale/id";
import domain from "../util/domain";
import Alert from "../util/alert";
import Loading from "../common/loading.component";
import MCUPdf from "./pdf/mcu.component";
import { QRCode } from "react-qrcode-logo";
import DiagnosticResultPdf from "./pdf/diagnostic.component";

export default function MCU() {
    let { mrn, id } = useParams();

    const [data, setData] = useState();
    const [notification, setNotification] = useState();
    const [qrCodeDataUri, setQRCodeDataUri] = useState();
    const [diagnosticHistory, setDiagnosticHistory] = useState([]);

    useEffect(() => {
        async function fetchData() {
            await axios
                .get(`${domain}/patients/` + mrn + "/" + id + "/mcu")
                .then((response) => {
                    setData(response.data);
                    var history = [];
                    if (response.data.patient.diagnosticHistory) {
                        for (var index in response.data.patient.diagnosticHistory.filter((item) => {
                            return (
                                new Date(item.createdAt).getTime() >= new Date(response.data.mcu.requestedAt).getTime() &&
                                new Date(item.createdAt).getTime() <= new Date(response.data.mcu.updatedAt).getTime()
                            );
                        })) {
                            var item = response.data.patient.diagnosticHistory[index];
                            var images = [];
                            if (item.attachmentUrls) {
                                for (var index2 in item.attachmentUrls) {
                                    images.push(item.attachmentUrls[index2]);
                                }
                            }
                            history.push({
                                result: item.result,
                                images: images,
                                name: item.name
                            });
                        }
                        setDiagnosticHistory(history);
                    }

                    var delayInMilliseconds = 500; //1 second
                    setTimeout(function () {
                        //your code to be executed after 1 second
                        setQRCodeDataUri(document.querySelector("canvas").toDataURL("image/jpg", 0.3));
                    }, delayInMilliseconds);
                })
                .catch((error) => {
                    if (!error.response) {
                        // network error
                        setNotification({
                            message: "Exception: " + error,
                            type: "danger"
                        });
                    } else {
                        //setError(error.response.data.msg);
                        setNotification({
                            message: error.response.data.msg,
                            type: "danger"
                        });
                    }
                });
        }
        fetchData();
    }, [mrn, id]);

    return (
        <div className="mt-3">
            {notification ? (
                <Alert message={notification.message} type={notification.type} />
            ) : data ? (
                <div>
                    <h5 className="text-center">MCU Data</h5>
                    <table className="table table-sm">
                        <tbody>
                            <tr>
                                <th>MRN</th>
                                <td>{data.patient.mrn}</td>
                            </tr>
                            <tr>
                                <th>Nama / Name</th>
                                <td>{data.patient.fullName}</td>
                            </tr>
                            <tr>
                                <th>Tgl. Pemeriksaan / Exam Date</th>
                                <td>{moment(data.mcu.createdAt).format("DD-MM-YYYY")}</td>
                            </tr>
                            <tr>
                                <th>Dokter / Doctor</th>
                                <td>{data.mcu.performedBy}</td>
                            </tr>
                            <tr style={{ visibility: "hidden", display: "none" }}>
                                <td colSpan={2}>
                                    <div id="canvas">
                                        <QRCode
                                            value={"https://archive.rscitrasundari.com/mcu/" + data.patient.mrn + "/" + data.mcu._id}
                                            size={95}
                                            qrStyle="dots"
                                            removeQrCodeBehindLogo={true}
                                            logoImage="/img/Logo.png"
                                            logoWidth={20}
                                        />
                                    </div>
                                </td>
                            </tr>
                            {qrCodeDataUri && (
                                <>
                                    <tr>
                                        <td colSpan={2} className="text-center">
                                            <MCUPdf
                                                patient={data.patient}
                                                data={data.mcu}
                                                qrImage={qrCodeDataUri}
                                                labHistory={data.patient.labHistory.filter((item) => {
                                                    return (
                                                        new Date(item.createdAt).getTime() >= new Date(data.mcu.requestedAt).getTime() &&
                                                        new Date(item.createdAt).getTime() <= new Date(data.mcu.updatedAt).getTime()
                                                    );
                                                })}
                                                radiologyHistory={data.patient.radiologyHistory.filter((item) => {
                                                    return (
                                                        new Date(item.createdAt).getTime() >= new Date(data.mcu.requestedAt).getTime() &&
                                                        new Date(item.createdAt).getTime() <= new Date(data.mcu.updatedAt).getTime()
                                                    );
                                                })}
                                                diagnosticHistory={diagnosticHistory}
                                            />
                                        </td>
                                    </tr>
                                    {data.patient.radiologyHistory
                                        .filter((item) => {
                                            return (
                                                new Date(item.createdAt).getTime() >= new Date(data.mcu.requestedAt).getTime() &&
                                                new Date(item.createdAt).getTime() <= new Date(data.mcu.updatedAt).getTime()
                                            );
                                        })
                                        .map((radiology) => (
                                            <>
                                                {radiology.attachmentUrls &&
                                                    radiology.attachmentUrls.map((attachment, index) => (
                                                        <tr>
                                                            <td colSpan={2} className="text-center">
                                                                <a target="_blank" rel="noreferrer" href={attachment} className="btn btn-sm btn-outline-secondary">
                                                                    Download Photo {radiology.name} {index > 0 ? "ke-" + parseInt(index + 1) : ""}
                                                                </a>
                                                            </td>
                                                        </tr>
                                                    ))}
                                            </>
                                        ))}
                                </>
                            )}
                            <tr className="table-primary text-center">
                                <th colSpan={2}>Terverifikasi / Verified</th>
                            </tr>
                        </tbody>
                    </table>
                </div>
            ) : (
                <Loading />
            )}
        </div>
    );
}

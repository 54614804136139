import React from "react";
import { PDFDownloadLink, Page, Text, View, Document, StyleSheet, Font, Image } from "@react-pdf/renderer";
import Header from "./header.component";
import moment from "moment";
import getAge from "../misc/getAge";
import getBMI from "../misc/getBMI";
import getSalutation from "../misc/getSalutation";
import "moment/locale/id";

Font.register({
    family: "Segoe UI",
    fonts: [
        {
            src: "/fonts/Segoe UI/Segoe UI.ttf"
        },
        {
            src: "/fonts/Segoe UI/Segoe UI Bold.ttf",
            fontWeight: "bold"
        },
        {
            src: "/fonts/Segoe UI/Segoe UI Italic.ttf",
            fontWeight: "normal",
            fontStyle: "italic"
        }
    ]
});

// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: "col",
        marginLeft: 30,
        marginTop: 10,
        marginRight: 20,
        fontFamily: "Segoe UI",
        fontSize: "9",
        paddingRight: 60
    },
    section: {
        padding: 10
    },
    tableHeader: {
        fontWeight: "bold",
        paddingBottom: 5
    },
    tableContent: {
        paddingBottom: 5
    },
    tableCoverHeader: {
        fontWeight: "bold",
        paddingBottom: 5,
        fontSize: 14
    },
    tableCoverContent: {
        paddingBottom: 5,
        fontSize: 14
    },
    footer: {
        position: "absolute",
        bottom: 30,
        left: 0,
        right: 0
    }
});

// Create Document Component

const MyDocument = ({ data, qrImage }) => (
    <Document>
        <Page size="A4" style={styles.page}>
            <Header />
            <View>
                <Text style={{ textAlign: "right", fontSize: "14", fontWeight: "bold" }}>RAHASIA</Text>
                <Text style={{ textAlign: "center", fontSize: "15", fontWeight: "bold" }}>RESUME MEDICAL CHECK UP</Text>
            </View>
            <View style={styles.section}>
                <View style={{ flexDirection: "col" }}>
                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item, index) => (
                        <View key={index} style={{ flexDirection: "row" }}>
                            <Text style={[styles.tableCoverContent]}>&nbsp;</Text>
                        </View>
                    ))}
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableContent]}></Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableCoverHeader, { width: "40%" }]}>Tanggal Pemeriksaan</Text>
                        <Text style={[styles.tableCoverContent, { width: "60%" }]}>{moment(data.createdAt).format("dddd, D MMMM YYYY")}</Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableCoverHeader, { width: "40%" }]}>Nama</Text>
                        <Text style={[styles.tableCoverContent, { width: "60%" }]}>
                            {getSalutation(data.patient.fullName, data.patient.sex, data.patient.dateOfBirth, data.patient.maritalStatus)}
                            {data.patient.fullName}
                        </Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableCoverHeader, { width: "40%" }]}>Tanggal Lahir</Text>
                        <Text style={[styles.tableCoverContent, { width: "60%" }]}>{moment(data.patient.dateOfBirth).format("D MMMM YYYY")}</Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableCoverHeader, { width: "40%" }]}>Penjamin / Perusahaan</Text>
                        <Text style={[styles.tableCoverContent, { width: "60%" }]}>{data.guarantorName}</Text>
                    </View>
                </View>
            </View>
        </Page>
        <Page size="A4" style={styles.page}>
            <Header />
            <View>
                <Text style={{ textAlign: "center", fontSize: "13" }}>Hasil Medical Check Up</Text>
                <Text style={{ textAlign: "right" }}>Halaman 1 / 4</Text>
            </View>
            <View style={styles.section}>
                <View style={{ flexDirection: "col" }}>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader]}>Data Diri</Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "40%" }]}>No MCU</Text>
                        <Text style={[styles.tableContent, { width: "60%" }]}>{data.mcu_no}</Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "40%" }]}>Tanggal Pemeriksaan</Text>
                        <Text style={[styles.tableContent, { width: "60%" }]}>{moment(data.createdAt).format("dddd, D MMMM YYYY HH:mm")}</Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "40%" }]}>MRN</Text>
                        <Text style={[styles.tableContent, { width: "60%" }]}>{data.patient.mrn}</Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "40%" }]}>Nama Lengkap</Text>
                        <Text style={[styles.tableContent, { width: "60%" }]}>
                            {getSalutation(data.patient.fullName, data.patient.sex, data.patient.dateOfBirth, data.patient.maritalStatus)}
                            {data.patient.fullName}
                        </Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "40%" }]}>Tanggal Lahir</Text>
                        <Text style={[styles.tableContent, { width: "60%" }]}>{moment(data.patient.dateOfBirth).format("D MMMM YYYY")}</Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "40%" }]}>Usia</Text>
                        <Text style={[styles.tableContent, { width: "60%" }]}>{getAge(data.patient.dateOfBirth, new Date())}</Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "40%" }]}>Jenis Kelamin</Text>
                        <Text style={[styles.tableContent, { width: "60%" }]}>{data.patient.sex}</Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "40%" }]}>Pekerjaan</Text>
                        <Text style={[styles.tableContent, { width: "60%" }]}>{data.occupation}</Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "40%" }]}>Penjamin / Perusahaan</Text>
                        <Text style={[styles.tableContent, { width: "60%" }]}>{data.patient.guarantorName}</Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "40%" }]}>Alamat</Text>
                        <Text style={[styles.tableContent, { width: "60%" }]}>{data.address}</Text>
                    </View>
                </View>
            </View>
            <View style={styles.footer}>
                <Text>
                    {getSalutation(data.patient.fullName, data.patient.sex, data.patient.dateOfBirth, data.patient.maritalStatus)}
                    {data.patient.fullName}
                </Text>
                <Text>MCU {data.mcu_no}</Text>
            </View>
        </Page>
        <Page size="A4" style={styles.page}>
            <Header />
            <View>
                <Text style={{ textAlign: "right" }}>Halaman 2 / 4</Text>
            </View>
            <View style={styles.section}>
                <View style={{ flexDirection: "col" }}>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader]}>Detail Pemeriksaan I</Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "30%" }]}>Anamnesa Singkat</Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>Riwayat Penyakit Sekarang</Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>{data.currentDisease}</Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "30%" }]}></Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>Riwayat Penyakit Dahulu</Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>{data.previousDisease}</Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "30%" }]}></Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>Riwayat Penyakit Keluarga</Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>{data.familyDisease}</Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "30%" }]}></Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>Riwayat Alergi Makan & Obat</Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>{data.foodMedicineAlergic}</Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "30%" }]}></Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>Riwayat Merokok</Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>{data.smoking}</Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "30%" }]}></Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>Riwayat Alkohol</Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>{data.alcohol}</Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableContent]}></Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "30%" }]}></Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>Keadaan Umum</Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>{data.generalCondition}</Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "30%" }]}></Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>Bentuk Tubuh</Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>{data.bodyShape}</Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "30%" }]}></Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>Kesadaran</Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>{data.awareness}</Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "30%" }]}></Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>Frekuensi Nadi</Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>
                            {data.pulseFrequency}x / Menit, {data.pulseStrong}
                        </Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "30%" }]}></Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>Tekanan Darah</Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>{data.bloodPressure}</Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "30%" }]}></Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>Suhu</Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>{data.temperature}&deg;C</Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "30%" }]}></Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>Tinggi Badan</Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>{data.height}</Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "30%" }]}></Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>Berat Badan</Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>{data.weight}</Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "30%" }]}></Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>BMI</Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>{getBMI(data.weight, data.height)}</Text>
                    </View>
                </View>
            </View>
            <View style={styles.footer}>
                <Text>
                    {getSalutation(data.patient.fullName, data.patient.sex, data.patient.dateOfBirth, data.patient.maritalStatus)}
                    {data.patient.fullName}
                </Text>
                <Text>MCU {data.mcu_no}</Text>
            </View>
        </Page>
        <Page size="A4" style={styles.page}>
            <Header />
            <View>
                <Text style={{ textAlign: "right" }}>Halaman 3 / 4</Text>
            </View>
            <View style={styles.section}>
                <View style={{ flexDirection: "col" }}>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader]}>Detail Pemeriksaan II</Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "20%" }]}>Kepala</Text>
                        <Text style={[styles.tableHeader, { width: "20%" }]}>Mata</Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>Konjungtiva</Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>{data.konjungtiva}</Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "20%" }]}></Text>
                        <Text style={[styles.tableHeader, { width: "20%" }]}></Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>Sklera</Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>{data.sklera}</Text>
                    </View>
                    {data.colorBlind !== "Tidak Dilakukan Pemeriksaan" && (
                        <View style={{ flexDirection: "row" }}>
                            <Text style={[styles.tableHeader, { width: "20%" }]}></Text>
                            <Text style={[styles.tableHeader, { width: "20%" }]}></Text>
                            <Text style={[styles.tableContent, { width: "30%" }]}>Buta Warna</Text>
                            <Text style={[styles.tableContent, { width: "30%" }]}>{data.colorBlind}</Text>
                        </View>
                    )}
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "20%" }]}></Text>
                        <Text style={[styles.tableHeader, { width: "20%" }]}></Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>Visus</Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>
                            Kiri: {data.visusLeft}, Kanan: {data.visusRight}
                        </Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "20%" }]}></Text>
                        <Text style={[styles.tableHeader, { width: "20%" }]}>Leher</Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>Kelenjar Limfe</Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>{data.limfe}</Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "20%" }]}></Text>
                        <Text style={[styles.tableHeader, { width: "20%" }]}>Leher</Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>Kelenjar Tiroid</Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>{data.gondok}</Text>
                    </View>
                    {data.ear && data.ear !== "Tidak Diperiksa" && (
                        <View style={{ flexDirection: "row" }}>
                            <Text style={[styles.tableHeader, { width: "20%" }]}></Text>
                            <Text style={[styles.tableHeader, { width: "20%" }]}>Telinga</Text>
                            <Text style={[styles.tableContent, { width: "30%" }]}>{data.ear}</Text>
                            <Text style={[styles.tableContent, { width: "30%" }]}></Text>
                        </View>
                    )}
                    {data.nose && data.nose !== "Tidak Diperiksa" && (
                        <View style={{ flexDirection: "row" }}>
                            <Text style={[styles.tableHeader, { width: "20%" }]}></Text>
                            <Text style={[styles.tableHeader, { width: "20%" }]}>Hidung</Text>
                            <Text style={[styles.tableContent, { width: "30%" }]}>{data.nose}</Text>
                            <Text style={[styles.tableContent, { width: "30%" }]}></Text>
                        </View>
                    )}
                    {data.throat && data.throat !== "Tidak Diperiksa" && (
                        <View style={{ flexDirection: "row" }}>
                            <Text style={[styles.tableHeader, { width: "20%" }]}></Text>
                            <Text style={[styles.tableHeader, { width: "20%" }]}>Tenggorokan</Text>
                            <Text style={[styles.tableContent, { width: "30%" }]}>{data.throat}</Text>
                            <Text style={[styles.tableContent, { width: "30%" }]}></Text>
                        </View>
                    )}
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "20%" }]}>Dada</Text>
                        <Text style={[styles.tableHeader, { width: "20%" }]}>Inspeksi</Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>{data.inspeksiBreast}</Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}></Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "20%" }]}></Text>
                        <Text style={[styles.tableHeader, { width: "20%" }]}>Paru</Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>Gerak Dada</Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>{data.lungMovement}</Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "20%" }]}></Text>
                        <Text style={[styles.tableHeader, { width: "20%" }]}></Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>Bunyi Nafas</Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>{data.lungSound}</Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "20%" }]}></Text>
                        <Text style={[styles.tableHeader, { width: "20%" }]}></Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>Bunyi Tambahan</Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>{data.otherLungSound}</Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "20%" }]}></Text>
                        <Text style={[styles.tableHeader, { width: "20%" }]}>Jantung</Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>Bunyi Jantung</Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>{data.otherLungSound}</Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "20%" }]}></Text>
                        <Text style={[styles.tableHeader, { width: "20%" }]}></Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>Bunyi Tambahan</Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>{data.otherHeartSound}</Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "20%" }]}></Text>
                        <Text style={[styles.tableHeader, { width: "20%" }]}>Lain-lain</Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}></Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>{data.otherBreast}</Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableContent]}></Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "20%" }]}>Abdomen</Text>
                        <Text style={[styles.tableHeader, { width: "20%" }]}></Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>Inspeksi</Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>{data.inspeksi}</Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "20%" }]}></Text>
                        <Text style={[styles.tableHeader, { width: "20%" }]}></Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>Auskultasi</Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>{data.auskultasi}</Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "20%" }]}></Text>
                        <Text style={[styles.tableHeader, { width: "20%" }]}></Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>Palpasi</Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>{data.palpasi}</Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "20%" }]}></Text>
                        <Text style={[styles.tableHeader, { width: "20%" }]}></Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>Perkusi</Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>{data.perkusi}</Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "20%" }]}></Text>
                        <Text style={[styles.tableHeader, { width: "20%" }]}></Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>Lain-lain</Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>{data.otherAbdomen}</Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableContent]}></Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "20%" }]}>Punggung</Text>
                        <Text style={[styles.tableHeader, { width: "20%" }]}></Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>Nyeri Ketok CVA</Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>{data.backCVA}</Text>
                    </View>
                    {data.patient.sex === "Perempuan" && (
                        <>
                            <View style={{ flexDirection: "row" }}>
                                <Text style={[styles.tableContent]}></Text>
                            </View>
                            <View style={{ flexDirection: "row" }}>
                                <Text style={[styles.tableHeader, { width: "20%" }]}>Genitalia Interna</Text>
                                <Text style={[styles.tableHeader, { width: "20%" }]}></Text>
                                <Text style={[styles.tableContent, { width: "30%" }]}>Inspeksi</Text>
                                <Text style={[styles.tableContent, { width: "30%" }]}>{data.inspeksiGenitalia}</Text>
                            </View>
                            <View style={{ flexDirection: "row" }}>
                                <Text style={[styles.tableHeader, { width: "20%" }]}></Text>
                                <Text style={[styles.tableHeader, { width: "20%" }]}></Text>
                                <Text style={[styles.tableContent, { width: "30%" }]}>Inspekulo</Text>
                                <Text style={[styles.tableContent, { width: "30%" }]}>{data.inspekulo}</Text>
                            </View>
                            <View style={{ flexDirection: "row" }}>
                                <Text style={[styles.tableHeader, { width: "20%" }]}></Text>
                                <Text style={[styles.tableHeader, { width: "20%" }]}></Text>
                                <Text style={[styles.tableContent, { width: "30%" }]}>VT</Text>
                                <Text style={[styles.tableContent, { width: "30%" }]}>{data.vt}</Text>
                            </View>
                        </>
                    )}
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableContent]}></Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "20%" }]}>Ekstremitas</Text>
                        <Text style={[styles.tableHeader, { width: "20%" }]}></Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>Atas</Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>{data.upperExtremity}</Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "20%" }]}></Text>
                        <Text style={[styles.tableHeader, { width: "20%" }]}></Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>Bawah</Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>{data.lowerExtremity}</Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableContent]}></Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "20%" }]}>Kulit</Text>
                        <Text style={[styles.tableHeader, { width: "20%" }]}></Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>Taro</Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>{data.tattoo}</Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "20%" }]}></Text>
                        <Text style={[styles.tableHeader, { width: "20%" }]}></Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>Penyakit Kulit</Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>{data.skinDisease}</Text>
                    </View>
                </View>
            </View>
            <View style={styles.footer}>
                <Text>
                    {getSalutation(data.patient.fullName, data.patient.sex, data.patient.dateOfBirth, data.patient.maritalStatus)}
                    {data.patient.fullName}
                </Text>
                <Text>MCU {data.mcu_no}</Text>
            </View>
        </Page>
        <Page size="A4" style={styles.page}>
            <Header />
            <View>
                <Text style={{ textAlign: "right" }}>Halaman 4 / 4</Text>
            </View>
            <View style={styles.section}>
                <View style={{ flexDirection: "col" }}>
                    {data.rombergRapatBuka && data.rombergRapatBuka !== "Tidak Diperiksa" && (
                        <View style={{ flexDirection: "row" }}>
                            <Text style={[styles.tableHeader, { width: "30%" }]}>Romberg</Text>
                            <Text style={[styles.tableContent, { width: "40%" }]}>Kaki Rapat, Mata Terbuka (30 detik)</Text>
                            <Text style={[styles.tableContent, { width: "30%" }]}>{data.rombergRapatBuka}</Text>
                        </View>
                    )}
                    {data.rombergRapatTutup && data.rombergRapatTutup !== "Tidak Diperiksa" && (
                        <View style={{ flexDirection: "row" }}>
                            <Text style={[styles.tableHeader, { width: "30%" }]}></Text>
                            <Text style={[styles.tableContent, { width: "40%" }]}>Kaki Rapat, Mata Tertutup (30 detik)</Text>
                            <Text style={[styles.tableContent, { width: "30%" }]}>{data.rombergRapatTutup}</Text>
                        </View>
                    )}
                    {data.rombergTandemBuka && data.rombergTandemBuka !== "Tidak Diperiksa" && (
                        <View style={{ flexDirection: "row" }}>
                            <Text style={[styles.tableHeader, { width: "30%" }]}></Text>
                            <Text style={[styles.tableContent, { width: "40%" }]}>Kaki Tandem, Mata Terbuka (30 detik)</Text>
                            <Text style={[styles.tableContent, { width: "30%" }]}>{data.rombergTandemBuka}</Text>
                        </View>
                    )}
                    {data.rombergTandemTutup && data.rombergTandemTutup !== "Tidak Diperiksa" && (
                        <View style={{ flexDirection: "row" }}>
                            <Text style={[styles.tableHeader, { width: "30%" }]}></Text>
                            <Text style={[styles.tableContent, { width: "40%" }]}>Kaki Tandem, Mata Tertutup (30 detik)</Text>
                            <Text style={[styles.tableContent, { width: "30%" }]}>{data.rombergTandemTutup}</Text>
                        </View>
                    )}
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "30%" }]}>Pemeriksaan Penunjang</Text>
                        <Text style={[styles.tableContent, { width: "20%" }]}>EKG</Text>
                        <Text style={[styles.tableContent, { width: "50%" }]}>{data.ekg}</Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "30%" }]}></Text>
                        <Text style={[styles.tableContent, { width: "20%" }]}>Laboratorium</Text>
                        <Text style={[styles.tableContent, { width: "50%" }]}>{data.lab}</Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "30%" }]}></Text>
                        <Text style={[styles.tableContent, { width: "20%" }]}>Radiologi</Text>
                        <Text style={[styles.tableContent, { width: "50%" }]}>{data.radiology}</Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableContent]}></Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "30%" }]}>Kesimpulan</Text>
                        <Text style={[styles.tableContent, { width: "70%" }]}>{data.summary}</Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableContent]}></Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "30%" }]}>Anjuran</Text>
                        <Text style={[styles.tableContent, { width: "70%" }]}>{data.advice}</Text>
                    </View>
                    <View style={{ flexDirection: "row", marginTop: 10 }}>
                        <View style={{ flexDirection: "col", width: "30%", alignItems: "center" }}></View>
                        <View style={{ flexDirection: "col", width: "40%", alignItems: "center" }}></View>
                        <View style={{ flexDirection: "col", width: "30%" }}>
                            <Text style={{ paddingBottom: 5 }}>Cilegon, {moment(data.createdAt).format("D MMMM YYYY")}</Text>
                            <Image source={qrImage} style={{ height: 75, width: 75 }} />
                            <Text style={{ paddingBottom: 5 }}>{data.performedBy}</Text>
                        </View>
                    </View>
                </View>
            </View>
            <View style={styles.footer}>
                <Text>
                    {getSalutation(data.patient.fullName, data.patient.sex, data.patient.dateOfBirth, data.patient.maritalStatus)}
                    {data.patient.fullName}
                </Text>
                <Text>MCU {data.mcu_no}</Text>
            </View>
        </Page>
    </Document>
);

export default function MCU({ patient, data, qrImage, notes }) {
    return (
        <PDFDownloadLink
            className="btn btn-sm btn-outline-primary"
            document={<MyDocument patient={patient} data={data} qrImage={qrImage} notes={notes} />}
            fileName={"MCU" + data.mcu_no + " - " + patient.mrn + ".pdf"}
        >
            {({ blob, url, loading, error }) => (loading ? "Loading ..." : "Download PDF - Hasil MCU")}
        </PDFDownloadLink>
    );
}

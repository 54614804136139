import React from "react";

export default function Loading() {
    return (
        <>
            <div className="d-flex align-items-center mt-3 ml-3 mr-3">
                <strong>Memproses validasi / Processing validation ...</strong>
                <div className="spinner-border ml-auto" role="status" aria-hidden="true"></div>
            </div>
        </>
    );
}

/*
<div className="d-flex align-items-center">
            <strong>Loading...</strong>
            <div className="spinner-border ml-auto" role="status" aria-hidden="true"></div>
        </div>
        */

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import "moment/locale/id";
import domain from "../util/domain";
import Alert from "../util/alert";
import Loading from "../common/loading.component";
import getZeroPad from "./misc/getZeroPad";

export default function PurchaseOrder() {
    let { mrn, id } = useParams();

    const [data, setData] = useState();
    const [notification, setNotification] = useState();

    useEffect(() => {
        async function fetchData() {
            await axios
                .get(`${domain}/po/` + id)
                .then((response) => {
                    setData(response.data);
                })
                .catch((error) => {
                    if (!error.response) {
                        // network error
                        setNotification({
                            message: "Exception: " + error,
                            type: "danger"
                        });
                    } else {
                        //setError(error.response.data.msg);
                        setNotification({
                            message: error.response.data.msg,
                            type: "danger"
                        });
                    }
                });
        }
        fetchData();
    }, [mrn, id]);

    return (
        <div className="mt-3">
            {notification ? (
                <Alert message={notification.message} type={notification.type} />
            ) : data ? (
                <div>
                    <h5 className="text-center">Purchase Order</h5>
                    <table className="table table-sm">
                        <tbody>
                            <tr>
                                <th>No</th>
                                <td>
                                    {data.year}
                                    {data.month}
                                    {data.date}
                                    {getZeroPad(data.po_no, 3)}
                                </td>
                            </tr>
                            <tr>
                                <th>Tanggal</th>
                                <td>{moment(data.createdAt).format("DD-MM-YYYY HH:mm")}</td>
                            </tr>
                            <tr>
                                <th>Distributor</th>
                                <td>{data.distributor}</td>
                            </tr>
                            <tr>
                                <td colSpan="2">
                                    <table className="table table-sm">
                                        <thead>
                                            <tr>
                                                <th>No</th>
                                                <th>Nama Barang</th>
                                                <th className="text-right">Jumlah</th>
                                                <th>Satuan</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.medicines.map(
                                                (medicine, index) =>
                                                    medicine.qty > 0 && (
                                                        <tr key={medicine._id}>
                                                            <td>{index + 1}</td>
                                                            <td>{medicine.name}</td>
                                                            <td className="text-right">{Math.ceil(medicine.qty / medicine.qjb)}</td>
                                                            <td>{medicine.qjb === 1 ? medicine.unit : "Box"}</td>
                                                        </tr>
                                                    )
                                            )}
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td>Dibuat oleh</td>
                                <td>{data.submittedBy}</td>
                            </tr>
                            <tr>
                                <td>Disetujui oleh</td>
                                <td>{data.approvedBy}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            ) : (
                <Loading />
            )}
        </div>
    );
}

import React from "react";
import { PDFDownloadLink, Page, Text, View, Document, StyleSheet, Font, Image } from "@react-pdf/renderer";
import Header from "./header.component";
import moment from "moment";
import getAge from "../misc/getAge";
import getBMI from "../misc/getBMI";
import getSalutation from "../misc/getSalutation";
import "moment/locale/id";
import toEnglishWord from "../misc/toEnglishWord";
import toNotFoundWord from "../misc/toNotFoundWord";
import convertRefValueToStr from "../misc/convertRefValueToStr";

Font.register({
    family: "Segoe UI",
    fonts: [
        {
            src: "/fonts/Segoe UI/Segoe UI.ttf"
        },
        {
            src: "/fonts/Segoe UI/Segoe UI Bold.ttf",
            fontWeight: "bold"
        },
        {
            src: "/fonts/Segoe UI/Segoe UI Italic.ttf",
            fontWeight: "normal",
            fontStyle: "italic"
        }
    ]
});

// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: "col",
        marginLeft: 30,
        marginTop: 10,
        marginRight: 20,
        fontFamily: "Segoe UI",
        fontSize: "9",
        paddingRight: 60,
        paddingBottom: 60
    },
    section: {
        padding: 10
    },
    tableHeader: {
        fontWeight: "bold",
        paddingBottom: 5
    },
    tableContent: {
        paddingBottom: 5
    },
    tableCoverHeader: {
        fontWeight: "bold",
        paddingBottom: 5,
        fontSize: 14
    },
    tableCoverContent: {
        paddingBottom: 5,
        fontSize: 14
    },
    footer: {
        position: "absolute",
        bottom: 30,
        left: 0,
        right: 0
    }
});

// Create Document Component

const MyDocument = ({ patient, data, qrImage, labHistory, radiologyHistory, diagnosticHistory }) => (
    <Document>
        <Page size="A4" style={styles.page}>
            <Header />
            <View>
                <Text style={{ textAlign: "right", fontSize: "14", fontWeight: "bold" }}>CONFIDENTIAL</Text>
                <Text style={{ textAlign: "center", fontSize: "15", fontWeight: "bold" }}>MEDICAL CHECK UP RESUME</Text>
            </View>
            <View style={styles.section}>
                <View style={{ flexDirection: "col" }}>
                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item, index) => (
                        <View key={index} style={{ flexDirection: "row" }}>
                            <Text style={[styles.tableCoverContent]}>&nbsp;</Text>
                        </View>
                    ))}
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableContent]}></Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableCoverHeader, { width: "40%" }]}>Tgl Pemeriksaan / Exam Date</Text>
                        <Text style={[styles.tableCoverContent, { width: "60%" }]}>{moment(data.createdAt).format("D MMM YYYY")}</Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableCoverHeader, { width: "40%" }]}>Nama / Name</Text>
                        <Text style={[styles.tableCoverContent, { width: "60%" }]}>
                            {getSalutation(data.patient.fullName, data.patient.sex, data.patient.dateOfBirth, data.patient.maritalStatus)}
                            {data.patient.fullName}
                        </Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableCoverHeader, { width: "40%" }]}>Tgl Lahir / DOB</Text>
                        <Text style={[styles.tableCoverContent, { width: "60%" }]}>{moment(data.patient.dateOfBirth).format("D MMM YYYY")}</Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableCoverHeader, { width: "40%" }]}>Penjamin / Guarantor</Text>
                        <Text style={[styles.tableCoverContent, { width: "60%" }]}>{data.guarantorName}</Text>
                    </View>
                </View>
            </View>
        </Page>
        <Page size="A4" style={styles.page}>
            <Header />
            <View>
                <Text style={{ textAlign: "center", fontSize: "13" }}>Hasil Medical Check Up</Text>
                <Text style={{ textAlign: "center", fontSize: "11" }}>Medical Check Up Result</Text>
                <Text style={{ textAlign: "right" }}>1 / 4</Text>
            </View>
            <View></View>
            <View style={styles.section}>
                <View style={{ flexDirection: "col" }}>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader]}>Data Diri / Personal Data</Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "40%" }]}>MCU No</Text>
                        <Text style={[styles.tableContent, { width: "60%" }]}>{data.mcu_no}</Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "40%" }]}>Tgl Pemeriksaan / Exam Date</Text>
                        <Text style={[styles.tableContent, { width: "60%" }]}>{moment(data.createdAt).format("D MMM YYYY")}</Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "40%" }]}>MRN</Text>
                        <Text style={[styles.tableContent, { width: "60%" }]}>{data.patient.mrn}</Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "40%" }]}>Nama / Name</Text>
                        <Text style={[styles.tableContent, { width: "60%" }]}>
                            {getSalutation(data.patient.fullName, data.patient.sex, data.patient.dateOfBirth, data.patient.maritalStatus)}
                            {data.patient.fullName}
                        </Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "40%" }]}>Tanggal Lahir / DOB</Text>
                        <Text style={[styles.tableContent, { width: "60%" }]}>{moment(data.patient.dateOfBirth).format("D MMM YYYY")}</Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "40%" }]}>Usia Age</Text>
                        <Text style={[styles.tableContent, { width: "60%" }]}>
                            {getAge(data.patient.dateOfBirth, new Date())} / {toEnglishWord(getAge(data.patient.dateOfBirth, new Date()))}
                        </Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "40%" }]}>Jenis Kelamin / Sex</Text>
                        <Text style={[styles.tableContent, { width: "60%" }]}>
                            {data.patient.sex} / {toEnglishWord(data.patient.sex)}
                        </Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "40%" }]}>Pekerjaan / Occupation</Text>
                        <Text style={[styles.tableContent, { width: "60%" }]}>{data.occupation}</Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "40%" }]}>Penjamin / Guarantor</Text>
                        <Text style={[styles.tableContent, { width: "60%" }]}>{data.patient.guarantorName}</Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "40%" }]}>Alamat / Address</Text>
                        <Text style={[styles.tableContent, { width: "60%" }]}>{data.address}</Text>
                    </View>
                </View>
            </View>
            <View style={styles.footer}>
                <Text>
                    {getSalutation(data.patient.fullName, data.patient.sex, data.patient.dateOfBirth, data.patient.maritalStatus)}
                    {data.patient.fullName}
                </Text>
                <Text>MCU {data.mcu_no}</Text>
            </View>
        </Page>
        <Page size="A4" style={styles.page}>
            <Header />
            <View>
                <Text style={{ textAlign: "right" }}>2 / 4</Text>
            </View>
            <View style={styles.section}>
                <View style={{ flexDirection: "col" }}>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader]}>Detail Pemeriksaan I / Detail Exam I</Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "20%" }]}>Anamnesa Singkat /</Text>
                        <Text style={[styles.tableContent, { width: "40%" }]}>Riwayat Penyakit Sekarang / Hist. of Present Illness</Text>
                        <Text style={[styles.tableContent, { width: "40%" }]}>
                            {data.currentDisease} {toNotFoundWord(data.currentDisease)}
                        </Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "20%" }]}>Brief Anamnesis</Text>
                        <Text style={[styles.tableContent, { width: "40%" }]}>Riwayat Penyakit Dahulu / Past Medical Hist.</Text>
                        <Text style={[styles.tableContent, { width: "40%" }]}>
                            {data.previousDisease} {toNotFoundWord(data.previousDisease)}
                        </Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "20%" }]}></Text>
                        <Text style={[styles.tableContent, { width: "40%" }]}>Riwayat Penyakit Keluarga / Family Medical Hist.</Text>
                        <Text style={[styles.tableContent, { width: "40%" }]}>
                            {data.familyDisease} {toNotFoundWord(data.familyDisease)}
                        </Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "20%" }]}></Text>
                        <Text style={[styles.tableContent, { width: "40%" }]}>Riwayat Alergi / Allergy Hist.</Text>
                        <Text style={[styles.tableContent, { width: "40%" }]}>
                            {data.foodMedicineAlergic} {toNotFoundWord(data.foodMedicineAlergic)}
                        </Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "20%" }]}></Text>
                        <Text style={[styles.tableContent, { width: "40%" }]}>Riwayat Merokok / Smoking Hist.</Text>
                        <Text style={[styles.tableContent, { width: "40%" }]}>{data.smoking === "Tidak" ? "Tidak / No" : data.smoking + " batang per hari / cigarettes per day"}</Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "20%" }]}></Text>
                        <Text style={[styles.tableContent, { width: "40%" }]}>Riwayat Alkohol / Alcohol Hist.</Text>
                        <Text style={[styles.tableContent, { width: "40%" }]}>{data.alcohol === "Tidak" ? "Tidak / No" : data.alcohol + " x per minggu / week"}</Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableContent]}></Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "20%" }]}></Text>
                        <Text style={[styles.tableContent, { width: "40%" }]}>Keadaan Umum / General Condition</Text>
                        <Text style={[styles.tableContent, { width: "40%" }]}>
                            {data.generalCondition} / {toEnglishWord(data.generalCondition)}
                        </Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "20%" }]}></Text>
                        <Text style={[styles.tableContent, { width: "40%" }]}>Bentuk Tubuh / Body Shape</Text>
                        <Text style={[styles.tableContent, { width: "40%" }]}>{data.bodyShape}</Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "20%" }]}></Text>
                        <Text style={[styles.tableContent, { width: "40%" }]}>Kesadaran / Awareness</Text>
                        <Text style={[styles.tableContent, { width: "40%" }]}>{data.awareness}</Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "20%" }]}></Text>
                        <Text style={[styles.tableContent, { width: "40%" }]}>Frekuensi Nadi / Pulse</Text>
                        <Text style={[styles.tableContent, { width: "40%" }]}>
                            {data.pulseFrequency}x / Min, {data.pulseStrong}
                        </Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "20%" }]}></Text>
                        <Text style={[styles.tableContent, { width: "40%" }]}>Tekanan Darah / BP</Text>
                        <Text style={[styles.tableContent, { width: "40%" }]}>{data.bloodPressure}</Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "20%" }]}></Text>
                        <Text style={[styles.tableContent, { width: "40%" }]}>Suhu / Temperature</Text>
                        <Text style={[styles.tableContent, { width: "40%" }]}>{data.temperature}&deg;C</Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "20%" }]}></Text>
                        <Text style={[styles.tableContent, { width: "40%" }]}>Tinggi Badan / Height</Text>
                        <Text style={[styles.tableContent, { width: "40%" }]}>{data.height}</Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "20%" }]}></Text>
                        <Text style={[styles.tableContent, { width: "40%" }]}>Berat Badan / Weight</Text>
                        <Text style={[styles.tableContent, { width: "40%" }]}>{data.weight}</Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "20%" }]}></Text>
                        <Text style={[styles.tableContent, { width: "40%" }]}>BMI</Text>
                        <Text style={[styles.tableContent, { width: "40%" }]}>{getBMI(data.weight, data.height)}</Text>
                    </View>
                </View>
            </View>
            <View style={styles.footer}>
                <Text>
                    {getSalutation(data.patient.fullName, data.patient.sex, data.patient.dateOfBirth, data.patient.maritalStatus)}
                    {data.patient.fullName}
                </Text>
                <Text>MCU {data.mcu_no}</Text>
            </View>
        </Page>
        <Page size="A4" style={styles.page}>
            <Header />
            <View>
                <Text style={{ textAlign: "right" }}>3 / 4</Text>
            </View>
            <View style={styles.section}>
                <View style={{ flexDirection: "col" }}>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader]}>Detail Pemeriksaan II / Detail Exam II</Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "20%" }]}>Kepala / Head</Text>
                        <Text style={[styles.tableHeader, { width: "20%" }]}>Mata / Eye</Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>Conjunctiva</Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>
                            {data.konjungtiva} / {toEnglishWord(data.konjungtiva)}
                        </Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "20%" }]}></Text>
                        <Text style={[styles.tableHeader, { width: "20%" }]}></Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>Sclera</Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>
                            {data.sklera} / {toEnglishWord(data.sklera)}
                        </Text>
                    </View>
                    {data.colorBlind !== "Tidak Dilakukan Pemeriksaan" && (
                        <View style={{ flexDirection: "row" }}>
                            <Text style={[styles.tableHeader, { width: "20%" }]}></Text>
                            <Text style={[styles.tableHeader, { width: "20%" }]}></Text>
                            <Text style={[styles.tableContent, { width: "30%" }]}>Buta Warna / Color Blind</Text>
                            <Text style={[styles.tableContent, { width: "30%" }]}>
                                {data.colorBlind} / {toEnglishWord(data.colorBlind)}
                            </Text>
                        </View>
                    )}
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "20%" }]}></Text>
                        <Text style={[styles.tableHeader, { width: "20%" }]}></Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>Visus</Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>
                            Kiri / Left: {data.visusLeft}, Kanan / Right: {data.visusRight}
                        </Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "20%" }]}></Text>
                        <Text style={[styles.tableHeader, { width: "20%" }]}>Leher / Neck</Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>Limfe / Lymph</Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>
                            {data.limfe} / {toEnglishWord(data.limfe)}
                        </Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "20%" }]}></Text>
                        <Text style={[styles.tableHeader, { width: "20%" }]}></Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>Tiroid</Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>
                            {data.gondok} / {toEnglishWord(data.gondok)}
                        </Text>
                    </View>
                    {data.ear && data.ear !== "Tidak Diperiksa" && (
                        <View style={{ flexDirection: "row" }}>
                            <Text style={[styles.tableHeader, { width: "20%" }]}></Text>
                            <Text style={[styles.tableHeader, { width: "20%" }]}>Telinga / Ear</Text>
                            <Text style={[styles.tableContent, { width: "30%" }]}>
                                {data.ear} / {toEnglishWord(data.ear)}
                            </Text>
                            <Text style={[styles.tableContent, { width: "30%" }]}></Text>
                        </View>
                    )}
                    {data.nose && data.nose !== "Tidak Diperiksa" && (
                        <View style={{ flexDirection: "row" }}>
                            <Text style={[styles.tableHeader, { width: "20%" }]}></Text>
                            <Text style={[styles.tableHeader, { width: "20%" }]}>Hidung / Nose</Text>
                            <Text style={[styles.tableContent, { width: "30%" }]}>
                                {data.nose} / {toEnglishWord(data.nose)}
                            </Text>
                            <Text style={[styles.tableContent, { width: "30%" }]}></Text>
                        </View>
                    )}
                    {data.throat && data.throat !== "Tidak Diperiksa" && (
                        <View style={{ flexDirection: "row" }}>
                            <Text style={[styles.tableHeader, { width: "20%" }]}></Text>
                            <Text style={[styles.tableHeader, { width: "20%" }]}>Tenggorokan / Throat</Text>
                            <Text style={[styles.tableContent, { width: "30%" }]}>
                                {data.throat} / {toEnglishWord(data.throat)}
                            </Text>
                            <Text style={[styles.tableContent, { width: "30%" }]}></Text>
                        </View>
                    )}
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "20%" }]}>Dada / Chest</Text>
                        <Text style={[styles.tableHeader, { width: "20%" }]}>Inspeksi / Inspection</Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>{data.inspeksiBreast}</Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}></Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "20%" }]}></Text>
                        <Text style={[styles.tableHeader, { width: "20%" }]}>Paru / Lung</Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>Gerak Dada / Chest Movement</Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>
                            {data.lungMovement} / {toEnglishWord(data.lungMovement)}
                        </Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "20%" }]}></Text>
                        <Text style={[styles.tableHeader, { width: "20%" }]}></Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>Bunyi Nafas / Breath Sound</Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>
                            {data.lungSound} / {toEnglishWord(data.lungSound)}
                        </Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "20%" }]}></Text>
                        <Text style={[styles.tableHeader, { width: "20%" }]}></Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>Bunyi Tambahan / Other Sound</Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>
                            {data.otherLungSound} / {toEnglishWord(data.otherLungSound)}
                        </Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "20%" }]}></Text>
                        <Text style={[styles.tableHeader, { width: "20%" }]}>Jantung / Heart</Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>Bunyi / Sound</Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>
                            {data.heartSound} / {toEnglishWord(data.heartSound)}
                        </Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "20%" }]}></Text>
                        <Text style={[styles.tableHeader, { width: "20%" }]}></Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>Bunyi Tambahan / Other Sound</Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>
                            {data.otherHeartSound} / {toEnglishWord(data.otherHeartSound)}
                        </Text>
                    </View>
                    {data.otherBreast && (
                        <View style={{ flexDirection: "row" }}>
                            <Text style={[styles.tableHeader, { width: "20%" }]}></Text>
                            <Text style={[styles.tableHeader, { width: "20%" }]}>Lain-lain / Other</Text>
                            <Text style={[styles.tableContent, { width: "30%" }]}></Text>
                            <Text style={[styles.tableContent, { width: "30%" }]}>{data.otherBreast}</Text>
                        </View>
                    )}
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableContent]}></Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "20%" }]}>Abdomen</Text>
                        <Text style={[styles.tableHeader, { width: "20%" }]}></Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>Inspeksi / Inspection</Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>
                            {data.inspeksi} / {toEnglishWord(data.inspeksi)}
                        </Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "20%" }]}></Text>
                        <Text style={[styles.tableHeader, { width: "20%" }]}></Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>Auscultation</Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>{data.auskultasi}</Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "20%" }]}></Text>
                        <Text style={[styles.tableHeader, { width: "20%" }]}></Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>Palpation</Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>{data.palpasi}</Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "20%" }]}></Text>
                        <Text style={[styles.tableHeader, { width: "20%" }]}></Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>Percussion</Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>{data.perkusi}</Text>
                    </View>
                    {data.otherAbdomen && (
                        <View style={{ flexDirection: "row" }}>
                            <Text style={[styles.tableHeader, { width: "20%" }]}></Text>
                            <Text style={[styles.tableHeader, { width: "20%" }]}></Text>
                            <Text style={[styles.tableContent, { width: "30%" }]}>Lain-lain / Other</Text>
                            <Text style={[styles.tableContent, { width: "30%" }]}>{data.otherAbdomen}</Text>
                        </View>
                    )}
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableContent]}></Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "20%" }]}>Punggung / Back</Text>
                        <Text style={[styles.tableHeader, { width: "20%" }]}></Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>Nyeri Ketok CVA / CVA Percussion Pain</Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>
                            {data.backCVA} / {toEnglishWord(data.backCVA)}
                        </Text>
                    </View>
                    {data.patient.sex === "Perempuan" && (
                        <>
                            <View style={{ flexDirection: "row" }}>
                                <Text style={[styles.tableContent]}></Text>
                            </View>
                            <View style={{ flexDirection: "row" }}>
                                <Text style={[styles.tableHeader, { width: "20%" }]}>Genitalia Interna</Text>
                                <Text style={[styles.tableHeader, { width: "20%" }]}></Text>
                                <Text style={[styles.tableContent, { width: "30%" }]}>Inspeksi / Inspection</Text>
                                <Text style={[styles.tableContent, { width: "30%" }]}>{data.inspeksiGenitalia}</Text>
                            </View>
                            <View style={{ flexDirection: "row" }}>
                                <Text style={[styles.tableHeader, { width: "20%" }]}></Text>
                                <Text style={[styles.tableHeader, { width: "20%" }]}></Text>
                                <Text style={[styles.tableContent, { width: "30%" }]}>Inspekulo</Text>
                                <Text style={[styles.tableContent, { width: "30%" }]}>{data.inspekulo}</Text>
                            </View>
                            <View style={{ flexDirection: "row" }}>
                                <Text style={[styles.tableHeader, { width: "20%" }]}></Text>
                                <Text style={[styles.tableHeader, { width: "20%" }]}></Text>
                                <Text style={[styles.tableContent, { width: "30%" }]}>VT</Text>
                                <Text style={[styles.tableContent, { width: "30%" }]}>{data.vt}</Text>
                            </View>
                        </>
                    )}
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableContent]}></Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "40%" }]}>Ekstremitas / Extremities</Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>Atas / Upper</Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>
                            {data.upperExtremity} / {toEnglishWord(data.upperExtremity)}
                        </Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "20%" }]}></Text>
                        <Text style={[styles.tableHeader, { width: "20%" }]}></Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>Bawah / Lower</Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>
                            {data.lowerExtremity} / {toEnglishWord(data.lowerExtremity)}
                        </Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableContent]}></Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "20%" }]}>Kulit / Skin</Text>
                        <Text style={[styles.tableHeader, { width: "20%" }]}></Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>Tato / Tattoo</Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>
                            {data.tattoo} / {toEnglishWord(data.tattoo)}
                        </Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "20%" }]}></Text>
                        <Text style={[styles.tableHeader, { width: "20%" }]}></Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>Penyakit Kulit / Skin Disease</Text>
                        <Text style={[styles.tableContent, { width: "30%" }]}>
                            {data.skinDisease} / {toEnglishWord(data.skinDisease)}
                        </Text>
                    </View>
                </View>
            </View>
            <View style={styles.footer}>
                <Text>
                    {getSalutation(data.patient.fullName, data.patient.sex, data.patient.dateOfBirth, data.patient.maritalStatus)}
                    {data.patient.fullName}
                </Text>
                <Text>MCU {data.mcu_no}</Text>
            </View>
        </Page>
        <Page size="A4" style={styles.page}>
            <Header />
            <View>
                <Text style={{ textAlign: "right" }}>4 / 4</Text>
            </View>
            <View style={styles.section}>
                <View style={{ flexDirection: "col" }}>
                    {data.rombergRapatBuka && data.rombergRapatBuka !== "Tidak Diperiksa" && (
                        <View style={{ flexDirection: "row" }}>
                            <Text style={[styles.tableHeader, { width: "30%" }]}>Romberg</Text>
                            <Text style={[styles.tableContent, { width: "40%" }]}>Kaki Rapat, Mata Terbuka / Feet Together, Eye Open (30 s)</Text>
                            <Text style={[styles.tableContent, { width: "30%" }]}>{data.rombergRapatBuka}</Text>
                        </View>
                    )}
                    {data.rombergRapatTutup && data.rombergRapatTutup !== "Tidak Diperiksa" && (
                        <View style={{ flexDirection: "row" }}>
                            <Text style={[styles.tableHeader, { width: "30%" }]}></Text>
                            <Text style={[styles.tableContent, { width: "40%" }]}>Kaki Rapat, Mata Tertutup / Feet Together, Eye Closed (30 s)</Text>
                            <Text style={[styles.tableContent, { width: "30%" }]}>{data.rombergRapatTutup}</Text>
                        </View>
                    )}
                    {data.rombergTandemBuka && data.rombergTandemBuka !== "Tidak Diperiksa" && (
                        <View style={{ flexDirection: "row" }}>
                            <Text style={[styles.tableHeader, { width: "30%" }]}></Text>
                            <Text style={[styles.tableContent, { width: "40%" }]}>Kaki Tandem, Mata Terbuka / Tandem Stance, Eye Open (30 s)</Text>
                            <Text style={[styles.tableContent, { width: "30%" }]}>{data.rombergTandemBuka}</Text>
                        </View>
                    )}
                    {data.rombergTandemTutup && data.rombergTandemTutup !== "Tidak Diperiksa" && (
                        <View style={{ flexDirection: "row" }}>
                            <Text style={[styles.tableHeader, { width: "30%" }]}></Text>
                            <Text style={[styles.tableContent, { width: "40%" }]}>Kaki Tandem, Mata Tertutup / Tandem Stance, Eye Closed (30 detik)</Text>
                            <Text style={[styles.tableContent, { width: "30%" }]}>{data.rombergTandemTutup}</Text>
                        </View>
                    )}
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "50%" }]}>Pemeriksaan Penunjang / Diagnostics</Text>
                        <Text style={[styles.tableContent, { width: "50%" }]}></Text>
                    </View>
                    {data.ekg && (
                        <View style={{ flexDirection: "row" }}>
                            <Text style={[styles.tableHeader, { width: "30%" }]}></Text>
                            <Text style={[styles.tableContent, { width: "20%" }]}>EKG</Text>
                            <Text style={[styles.tableContent, { width: "50%" }]}>{data.ekg}</Text>
                        </View>
                    )}
                    {data.audiometry && (
                        <View style={{ flexDirection: "row" }}>
                            <Text style={[styles.tableHeader, { width: "30%" }]}></Text>
                            <Text style={[styles.tableContent, { width: "20%" }]}>Audiometry</Text>
                            <Text style={[styles.tableContent, { width: "50%" }]}>{data.audiometry}</Text>
                        </View>
                    )}
                    {data.spirometry && (
                        <View style={{ flexDirection: "row" }}>
                            <Text style={[styles.tableHeader, { width: "30%" }]}></Text>
                            <Text style={[styles.tableContent, { width: "20%" }]}>Spirometry</Text>
                            <Text style={[styles.tableContent, { width: "50%" }]}>{data.spirometry}</Text>
                        </View>
                    )}
                    {data.treadmill && (
                        <View style={{ flexDirection: "row" }}>
                            <Text style={[styles.tableHeader, { width: "30%" }]}></Text>
                            <Text style={[styles.tableContent, { width: "20%" }]}>Treadmill</Text>
                            <Text style={[styles.tableContent, { width: "50%" }]}>{data.treadmill}</Text>
                        </View>
                    )}
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "30%" }]}></Text>
                        <Text style={[styles.tableContent, { width: "20%" }]}>Laboratorium</Text>
                        <Text style={[styles.tableContent, { width: "50%" }]}>{data.lab}</Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "30%" }]}></Text>
                        <Text style={[styles.tableContent, { width: "20%" }]}>Radiologi</Text>
                        <Text style={[styles.tableContent, { width: "50%" }]}>{data.radiology}</Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableContent]}></Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "30%" }]}>Kesimpulan / Summary</Text>
                        <Text style={[styles.tableContent, { width: "70%" }]}>{data.summary}</Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableContent]}></Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "30%" }]}>Anjuran / Advice</Text>
                        <Text style={[styles.tableContent, { width: "70%" }]}>{data.advice}</Text>
                    </View>
                    <View style={{ flexDirection: "row", marginTop: 10 }}>
                        <View style={{ flexDirection: "col", width: "30%", alignItems: "center" }}></View>
                        <View style={{ flexDirection: "col", width: "40%", alignItems: "center" }}></View>
                        <View style={{ flexDirection: "col", width: "30%" }}>
                            <Text style={{ paddingBottom: 5 }}>Cilegon, {moment(data.createdAt).format("D MMM YYYY")}</Text>
                            <Image source={qrImage} style={{ height: 75, width: 75 }} />
                            <Text style={{ paddingBottom: 5 }}>{data.performedBy}</Text>
                        </View>
                    </View>
                </View>
            </View>
            <View style={styles.footer}>
                <Text>
                    {getSalutation(data.patient.fullName, data.patient.sex, data.patient.dateOfBirth, data.patient.maritalStatus)}
                    {data.patient.fullName}
                </Text>
                <Text>MCU {data.mcu_no}</Text>
            </View>
        </Page>
        {labHistory.length > 0 && (
            <Page size="A4" style={styles.page}>
                <Header />
                <View>
                    <Text style={{ textAlign: "right" }}>LAMPIRAN / ATTACHMENT</Text>
                    <Text style={{ textAlign: "center", fontSize: "13" }}>Hasil Laboratorium</Text>
                    <Text style={{ textAlign: "center", fontSize: "11" }}>Laboratory Result</Text>
                </View>
                <View style={{ flexDirection: "col" }}>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableCoverContent]}>&nbsp;</Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "30%" }]}>Pemeriksaan / Exam</Text>
                        <Text style={[styles.tableHeader, { width: "15%" }]}>Hasil / Result</Text>
                        <Text style={[styles.tableHeader, { width: "10%" }]}></Text>
                        <Text style={[styles.tableHeader, { width: "10%" }]}>Satuan / Unit</Text>
                        <Text style={[styles.tableHeader, { width: "35%" }]}>Nilai Rujukan Normal / Reference</Text>
                    </View>
                    {labHistory.map((labResultData) => (
                        <>
                            <View style={{ flexDirection: "row" }}>
                                <Text style={[styles.tableHeader]}>{labResultData.name}</Text>
                            </View>
                            {labResultData.results.map((item, index) => (
                                <View key={index} style={{ flexDirection: "row" }}>
                                    <Text style={[styles.tableContent, { width: "30%" }]}>
                                        {item.name}
                                        {item.name.indexOf("Antigen SARS") >= 0 && (
                                            <>
                                                <br></br>
                                                <sup>Sampel Swab Nasofaring</sup>
                                            </>
                                        )}
                                    </Text>
                                    {item.indicator === "L" || item.indicator === "H" || item.indicator === "Positive" || item.indicator === "Inconclusive" ? (
                                        <Text style={[styles.tableContent, { width: "15%", color: "red", fontWeight: "bold" }]}>{item.value}</Text>
                                    ) : (
                                        <Text style={[styles.tableContent, { width: "15%", fontWeight: "bold" }]}>{labResultData.attachmentUrl ? "TERLAMPIR" : item.value}</Text>
                                    )}
                                    <Text style={[styles.tableContent, { width: "10%" }]}>{item.indicator}</Text>
                                    <Text style={[styles.tableContent, { width: "10%" }]}>{item.unit ? item.unit.replace("<sup>", "^").replace("</sup>", "") : ""}</Text>
                                    <Text style={[styles.tableContent, { width: "35%" }]}>{convertRefValueToStr(item.refValues, patient.dateOfBirth, item.name).replace("<br/>", ". ")}</Text>
                                </View>
                            ))}
                        </>
                    ))}
                </View>
                <View style={styles.footer}>
                    <Text>
                        {getSalutation(data.patient.fullName, data.patient.sex, data.patient.dateOfBirth, data.patient.maritalStatus)}
                        {data.patient.fullName}
                    </Text>
                    <Text>MCU {data.mcu_no}</Text>
                </View>
            </Page>
        )}
        {radiologyHistory.length > 0 && (
            <Page size="A4" style={styles.page}>
                <Header />
                <View>
                    <Text style={{ textAlign: "right" }}>LAMPIRAN / ATTACHMENT</Text>
                    <Text style={{ textAlign: "center", fontSize: "13" }}>Hasil Radiologi</Text>
                    <Text style={{ textAlign: "center", fontSize: "11" }}>Radiology Result</Text>
                </View>
                <View style={{ flexDirection: "col" }}>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableCoverContent]}>&nbsp;</Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "30%" }]}>Pemeriksaan / Exam</Text>
                        <Text style={[styles.tableHeader, { width: "70%" }]}>Expertise</Text>
                    </View>
                    {radiologyHistory.map((item, index) => (
                        <View key={index} style={{ flexDirection: "row" }}>
                            <Text style={[styles.tableContent, { width: "30%" }]}>{item.name}</Text>
                            <Text style={[styles.tableContent, { width: "70%" }]}>{item.result}</Text>
                        </View>
                    ))}
                </View>
                <View style={styles.footer}>
                    <Text>
                        {getSalutation(data.patient.fullName, data.patient.sex, data.patient.dateOfBirth, data.patient.maritalStatus)}
                        {data.patient.fullName}
                    </Text>
                    <Text>MCU {data.mcu_no}</Text>
                </View>
            </Page>
        )}
        {diagnosticHistory.length > 0 && (
            <Page size="A4" style={styles.page}>
                <Header />
                <View>
                    <Text style={{ textAlign: "right" }}>LAMPIRAN / ATTACHMENT</Text>
                    <Text style={{ textAlign: "center", fontSize: "13" }}>Hasil Diagnostik</Text>
                    <Text style={{ textAlign: "center", fontSize: "11" }}>Diagnostic Result</Text>
                </View>
                <View style={{ flexDirection: "col" }}>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableCoverContent]}>&nbsp;</Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.tableHeader, { width: "30%" }]}>Pemeriksaan / Exam</Text>
                        <Text style={[styles.tableHeader, { width: "70%" }]}>Expertise</Text>
                    </View>
                    {diagnosticHistory.map((item) => (
                        <>
                            {item.images.map((url, index) => (
                                <View key={index} style={{ flexDirection: "row" }}>
                                    <View style={{ flexDirection: "col", width: "30%" }}>
                                        <Text style={[styles.tableHeader]}>{index === 0 ? item.name : ""}</Text>
                                    </View>
                                    <View style={{ flexDirection: "col", width: "70%" }}>
                                        {item.name === "Spirometri" ? <Image src={url} style={{ height: 250, width: 125 }} /> : <Image src={url} style={{ height: 200 }} />}
                                        <Text style={[styles.tableContent]}>{index === 0 ? item.result : ""}</Text>
                                    </View>
                                </View>
                            ))}
                        </>
                    ))}
                </View>
                <View style={styles.footer}>
                    <Text>
                        {getSalutation(data.patient.fullName, data.patient.sex, data.patient.dateOfBirth, data.patient.maritalStatus)}
                        {data.patient.fullName}
                    </Text>
                    <Text>MCU {data.mcu_no}</Text>
                </View>
            </Page>
        )}
    </Document>
);

export default function MCU({ patient, data, qrImage, notes, labHistory, radiologyHistory, diagnosticHistory }) {
    return (
        <PDFDownloadLink
            className="btn btn-sm btn-outline-primary"
            document={<MyDocument patient={patient} data={data} qrImage={qrImage} notes={notes} labHistory={labHistory} radiologyHistory={radiologyHistory} diagnosticHistory={diagnosticHistory} />}
            fileName={"MCU" + data.mcu_no + " - " + patient.mrn + ".pdf"}
        >
            {({ blob, url, loading, error }) => (loading ? "Loading ..." : "Download PDF - Hasil MCU / MCU Result")}
        </PDFDownloadLink>
    );
}

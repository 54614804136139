import moment from "moment";
import getAgeInMonths from "./getAgeInMonths";
import getAgeInYears from "./getAgeInYears";
import toSexAbbreviation from "./toSexAbbreviation";

export default function convertRefValueToStr(refValues, dateOfBirth, name) {
    if (!refValues) return "";
    var ret = "";
    var momentDateOfBirth = moment(dateOfBirth);
    var currDate = moment(new Date());
    var ageInDays = moment.duration(currDate.diff(momentDateOfBirth)).asDays();
    var ageInMonths = getAgeInMonths(dateOfBirth, new Date());
    var ageInYears = getAgeInYears(dateOfBirth, new Date());

    if (refValues.length === 1) {
        if (refValues[0].normal) ret = refValues[0].normal;
        else if (refValues[0].ranges && refValues[0].ranges.length > 0) {
            var refs = [];
            for (var i in refValues[0].ranges) {
                if (refValues[0].ranges[i].low && refValues[0].ranges[i].high) {
                    refs.push(refValues[0].ranges[i].low + " - " + refValues[0].ranges[i].high + ": " + refValues[0].ranges[i].name);
                } else if (refValues[0].ranges[i].low) {
                    refs.push("≥ " + refValues[0].ranges[i].low + ": " + refValues[0].ranges[i].name);
                } else if (refValues[0].ranges[i].high) {
                    refs.push("< " + refValues[0].ranges[i].high + ": " + refValues[0].ranges[i].name);
                }
            }
            ret = refs.join("\n");
        } else {
            ret = refValues[0].low + " - " + refValues[0].high;
        }
    } else if (refValues.length === 2) {
        if (isReferencesIdenticalMenWomen(refValues)) {
            ret = refValues[0].low + " - " + refValues[0].high;
        } else {
            ret =
                toSexAbbreviation(refValues[0].sex) +
                ": " +
                refValues[0].low +
                " - " +
                refValues[0].high +
                " / " +
                toSexAbbreviation(refValues[1].sex) +
                ": " +
                refValues[1].low +
                " - " +
                refValues[1].high;
        }
    } else if (refValues.length === 5) {
        if (!dateOfBirth) return "";
        // newborns
        if (ageInDays <= 30) {
            ret = refValues[0].low + " - " + refValues[0].high;
        } else if (ageInDays <= 365) {
            ret = refValues[1].low + " - " + refValues[1].high;
        } else if (ageInDays <= 16 * 365) {
            ret = refValues[2].low + " - " + refValues[2].high;
        } else {
            if (refValues[3].low === refValues[4].low && refValues[3].high === refValues[4].high) {
                ret = refValues[3].low + " - " + refValues[3].high;
            } else if (isReferencesIdenticalMenWomen(refValues)) {
                ret = refValues[3].low + " - " + refValues[3].high;
            } else {
                ret =
                    toSexAbbreviation(refValues[3].sex) +
                    ": " +
                    refValues[3].low +
                    " - " +
                    refValues[3].high +
                    " / " +
                    toSexAbbreviation(refValues[4].sex) +
                    ": " +
                    refValues[4].low +
                    " - " +
                    refValues[4].high;
            }
        }
    } else if (refValues.length === 11 && name === "Creatinin") {
        if (!dateOfBirth) return { normal: "" };

        if (ageInDays <= 60) {
            ret = printLowHighRef(refValues[0].low, refValues[0].high);
        } else if (ageInMonths < 12) {
            ret = printLowHighRef(refValues[1].low, refValues[1].high);
        } else if (ageInYears < 3) {
            ret = printLowHighRef(refValues[2].low, refValues[2].high);
        } else if (ageInYears < 5) {
            ret = printLowHighRef(refValues[3].low, refValues[3].high);
        } else if (ageInYears < 7) {
            ret = printLowHighRef(refValues[4].low, refValues[4].high);
        } else if (ageInYears < 9) {
            ret = printLowHighRef(refValues[5].low, refValues[5].high);
        } else if (ageInYears < 11) {
            ret = printLowHighRef(refValues[6].low, refValues[6].high);
        } else if (ageInYears < 13) {
            ret = printLowHighRef(refValues[7].low, refValues[7].high);
        } else if (ageInYears < 14) {
            ret = printLowHighRef(refValues[8].low, refValues[8].high);
        } else {
            ret =
                toSexAbbreviation(refValues[9].sex) +
                ": " +
                printLowHighRef(refValues[9].low, refValues[9].high) +
                " / " +
                toSexAbbreviation(refValues[10].sex) +
                ": " +
                printLowHighRef(refValues[10].low, refValues[10].high);
        }
    } else if (refValues.length === 11 && name !== "Creatinin") {
        if (!dateOfBirth) return { normal: "" };

        if (ageInDays <= 3) {
            ret = printLowHighRef(refValues[0].low, refValues[0].high);
        } else if (ageInDays <= 9) {
            ret = printLowHighRef(refValues[1].low, refValues[1].high);
        } else if (ageInDays <= 14) {
            ret = printLowHighRef(refValues[2].low, refValues[2].high);
        } else if (ageInDays <= 30) {
            ret = printLowHighRef(refValues[3].low, refValues[3].high);
        } else if (ageInMonths < 7) {
            ret = printLowHighRef(refValues[4].low, refValues[4].high);
        } else if (ageInMonths < 25) {
            ret = printLowHighRef(refValues[5].low, refValues[5].high);
        } else if (ageInYears <= 5) {
            ret = printLowHighRef(refValues[6].low, refValues[6].high);
        } else if (ageInYears < 9) {
            ret = printLowHighRef(refValues[7].low, refValues[7].high);
        } else if (ageInYears < 13) {
            ret = printLowHighRef(refValues[8].low, refValues[8].high);
        } else if (ageInYears <= 18) {
            ret = printLowHighRef(refValues[9].low, refValues[9].high);
        } else {
            ret = printLowHighRef(refValues[10].low, refValues[10].high);
        }
    } else if (refValues.length === 13) {
        if (!dateOfBirth) return { normal: "" };

        if (ageInDays < 7) {
            ret = printLowHighRef(refValues[0].low, refValues[0].high);
        } else if (ageInDays < 14) {
            ret = printLowHighRef(refValues[1].low, refValues[1].high);
        } else if (ageInDays < 30) {
            ret = printLowHighRef(refValues[2].low, refValues[2].high);
        } else if (ageInMonths < 2) {
            ret = printLowHighRef(refValues[3].low, refValues[3].high);
        } else if (ageInMonths < 3) {
            ret = printLowHighRef(refValues[4].low, refValues[4].high);
        } else if (ageInMonths <= 6) {
            ret = printLowHighRef(refValues[5].low, refValues[5].high);
        } else if (ageInYears <= 2) {
            ret = printLowHighRef(refValues[6].low, refValues[6].high);
        } else if (ageInYears <= 6) {
            ret = printLowHighRef(refValues[7].low, refValues[7].high);
        } else if (ageInYears <= 12) {
            ret = printLowHighRef(refValues[8].low, refValues[8].high);
        } else if (ageInYears <= 18) {
            ret = toSexAbbreviation(refValues[9].sex) + ": " + printLowHighRef(refValues[9].low, refValues[9].high) + " / " + toSexAbbreviation(refValues[10].sex) + ": " + printLowHighRef(refValues[10].low, refValues[10].high);
        } else {
            ret =
                toSexAbbreviation(refValues[11].sex) +
                ": " +
                printLowHighRef(refValues[11].low, refValues[11].high) +
                " / " +
                toSexAbbreviation(refValues[12].sex) +
                ": " +
                printLowHighRef(refValues[12].low, refValues[12].high);
        }
    }
    return ret;
}

function printLowHighRef(low, high) {
    if (low === 0) {
        return "< " + high;
    }
    if (high === 0) {
        return "> " + low;
    } else {
        return low + " - " + high;
    }
}

function isReferencesIdenticalMenWomen(refValues) {
    if (refValues.length === 2) {
        if (refValues[0].low === refValues[1].low && refValues[0].high === refValues[1].high) return true;
    }
    if (refValues.length === 5) {
        if (refValues[3].low === refValues[4].low && refValues[3].high === refValues[4].high) return true;
    }
    return false;
}

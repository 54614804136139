import React from "react";
//import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
//import Login from "./component/auth/login.component";
//import Main from "./component/register/main.component";
//import PatientContext from "./context/patient.context";

import Navbar from "./common/navbar.component";
import Inpatient from "./component/inpatient.component";
import Birth from "./component/birth.component";
import MCU from "./component/mcu.component";
import Lab from "./component/lab.component";
import Radiology from "./component/radiology.component";
import Invoice from "./component/invoice.component";
import Letter from "./component/letter.component";
import IDCard from "./component/idcard.component";
import PurchaseOrder from "./component/purchaseorder.component";
import Diagnostic from "./component/diagnostic.component";

export default function Router() {
    return (
        <BrowserRouter>
            <div className="container">
                <Navbar />
                <Switch>
                    <Route path="/inpatient/:mrn/:id" component={Inpatient} />
                    <Route path="/birth/:mrn/:id" component={Birth} />
                    <Route path="/mcu/:mrn/:id" component={MCU} />
                    <Route path="/lab/:mrn/:id" component={Lab} />
                    <Route path="/radiology/:mrn/:id" component={Radiology} />
                    <Route path="/diagnostic/:mrn/:id" component={Diagnostic} />
                    <Route path="/invoice/:mrn/:id" component={Invoice} />
                    <Route path="/letter/:mrn/:id" component={Letter} />
                    <Route path="/idcard/:mrn/:id" component={IDCard} />
                    <Route path="/po/:id" component={PurchaseOrder} />
                </Switch>
            </div>
        </BrowserRouter>
    );
}

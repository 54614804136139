import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import "moment/locale/id";
import domain from "../util/domain";
import Alert from "../util/alert";
import Loading from "../common/loading.component";
import Sick from "./letters/sick.component";
import PregnancyLeave from "./letters/pregnancyleave.component";
import Discharge from "./letters/discharge.component";
import Vaccination from "./letters/vaccination.component";

//import WaitingButton from "../common/waitingbutton.component";
//import DatePicker, { registerLocale } from "react-datepicker";
//import id from "date-fns/locale/id";
//import Loading from "../common/loading.component";
//registerLocale("id", id);

export default function Letter() {
    let { mrn, id } = useParams();

    const [data, setData] = useState();
    const [notification, setNotification] = useState();

    useEffect(() => {
        async function fetchData() {
            await axios
                .get(`${domain}/patients/` + mrn + "/" + id + "/letter")
                .then((response) => {
                    setData(response.data);
                })
                .catch((error) => {
                    if (!error.response) {
                        // network error
                        setNotification({
                            message: "Exception: " + error,
                            type: "danger"
                        });
                    } else {
                        //setError(error.response.data.msg);
                        setNotification({
                            message: error.response.data.msg,
                            type: "danger"
                        });
                    }
                });
        }
        fetchData();
    }, [mrn, id]);

    return (
        <div className="mt-3">
            {notification ? (
                <Alert message={notification.message} type={notification.type} />
            ) : data && data.patient && data.letter ? (
                <>
                    {data.letter.type === "sick" && <Sick patient={data.patient} letter={data.letter} />}
                    {data.letter.type === "pregnancyleave" && <PregnancyLeave patient={data.patient} letter={data.letter} />}
                    {data.letter.type === "discharge" && <Discharge patient={data.patient} letter={data.letter} />}
                    {data.letter.type === "vaccination" && <Vaccination patient={data.patient} letter={data.letter} />}
                </>
            ) : (
                <Loading />
            )}
        </div>
    );
}
